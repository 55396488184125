import React from "react"
import { graphql } from "gatsby"
import './MainPages.css'
import sortBy from "lodash/sortBy"
import Link from "gatsby-link"
import Layout from './../components/Layouts';
import { Card, List, Typography, Row, Col } from 'antd';
import './Index.css'
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();


export const frontmatter = {
  layoutType: `page`,
  path: `/viz/`,
}

const colorGradients = [
  "linear-gradient(209.21deg, rgb(0,6,149) 13.57%, rgb(116,69,154) 98.38%)",
  "linear-gradient(209.21deg, rgb(87,15,141) 13.57%, rgb(243,91,160) 98.38%)",
  "linear-gradient(209.21deg, rgb(22,155,173) 13.57%, rgb(0,54,135) 98.38%)",
  "linear-gradient(209.21deg, rgb(73,38,173) 13.57%, rgb(52,53,99) 98.38%)",
  "linear-gradient(209.21deg, rgb(12,39,178) 13.57%, rgb(133,218,213) 98.38%)" 
]


const { Meta } = Card;
// const TITLES = ["Deep Learning", "Visualisation", "Bots"]
  const MakeLink = (props) => {
    return (
      <div>
        {
          props.path.startsWith("https")
            ? <a className="default-link" href={props.path} target="_" rel="noopener noreferrer"><li>{props.title}</li></a>
            : <Link className="default-link" to={props.path}><li>{props.title}</li></Link>
        }
      </div>
    )
  }
  
function VizCard(props) {
  const { index, node } = props
  const { frontmatter } = node || {}
  const { title, description, path, written } = frontmatter || {}
  
  console.log(`node`, node)
  console.log('color', index % colorGradients.length)
  return (
    <Col xs={{span: 12, offset: 1}} sm={{span: 8, offset: 1}} lg={{span: 5, offset: 2}} xl={{span: 3, offset: 2}}>
      <a href={path}>
      <Card
        // hoverable
        // title={title}
        // style={{ borderRadius: 10, width: 240, height: 200, backgroundImage: "linear-gradient(209.21deg, rgb(0,6,149) 13.57%, rgb(116,69,154) 98.38%)" }}        
        style={{ 
          borderRadius: 10, width: 240, height: 220,
          background: colorGradients[index % colorGradients.length]
         }}                 
        // style={{ width: 240, height: 200 }}
        // cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
      >
        <p style={{
          fontStyle: "normal",
          textAlign: "center", fontSize: 20, 
          fontWeight: "bold",
          lineHeight: "140%", color: "white",
          overflow: "hidden"
          }}>
          {title}
        </p>

        <p style={{
            textAlign: "center", fontSize: 14, 
            lineHeight: "18px", color: "rgba(255, 255, 255, 0.7)", 
            margin: 10
            }}>
          {description}
        </p>
        {/* <p style={{
          position: "relative",
          textAlign: "center",
          color: "rgba(255, 255, 255, 0.7)", 
          margin: "10 auto 0px",
          borderTop: 0,
        }}>
          {written}
        </p>         */}
      </Card>
      </a>
  </Col>    
  )
}

export default function DatavizIndex(props) {
  const { data, location } = props
  let mdEdges = data.allMarkdownRemark.edges;
  let jsEdges = data.allJavascriptFrontmatter.edges;
  let allPages = sortBy([...mdEdges, ...jsEdges],
      page => page.node.frontmatter.updated || page.node.frontmatter.written
    ).reverse();
    
  
    return (
      <Layout data={data} location={props.location}>
      <div className="container" style={{maxWidth: 1200, margin: "40px auto"}}>
        <Row gutter={[10,16]}>
          {allPages && allPages.map((item, idx) => <VizCard node={item.node} index={idx}/>)}
        </Row>
      </div>
      </Layout>
    )
}

class DatavizIndexV1 extends React.Component {

  render() {
    let mdEdges = this.props.data.allMarkdownRemark.edges;
    let jsEdges = this.props.data.allJavascriptFrontmatter.edges;
    let allPages = sortBy([...mdEdges, ...jsEdges],
        page => page.node.frontmatter.updated || page.node.frontmatter.written
      ).reverse();

    // console.log(allPages);

    return (
    <Layout data={this.props.data} location={this.props.location}>
    <div className="container" style={{maxWidth: 1200, margin: "40px auto"}}>
        {/* {allPages.map((page, i) => {
            return <p><Link to={page.node.frontmatter.path}><li>{page.node.frontmatter.title}</li></Link></p>
        })
        } */}
        <List
            bordered
            dataSource={allPages}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta 
                        title={<MakeLink path={item.node.frontmatter.path} title={item.node.frontmatter.title} />}
                        description={item.node.frontmatter.description}
                    />                    
                </List.Item>
            )}
        />
    </div>
    </Layout>
    )
  }
}

// export default DatavizIndexV1

export const showAllPageQuery = graphql`
  query showAllDatavizArticles {
    allJavascriptFrontmatter(filter: {frontmatter: {contentType: {eq: "dataviz"}}}, sort: { fields: [frontmatter___updated], order: DESC }) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            path
            title
            written
            layoutType
            contentType
            category
            description    
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }              
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {contentType: {eq: "dataviz"}}}, sort: { fields: [frontmatter___updated], order: DESC }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            path
            layoutType
            contentType
            written
            updated
            category
            description
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }              
            }
          }
          timeToRead
        }
      }
    }
    allDatavizJson {
      edges {
        node {
          title
          path
        }
      }  
    }
  
  }
`
